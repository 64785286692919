'use client'

import { RiSparklingFill } from '@remixicon/react'
import { Button, Form } from 'antd'

import useDrawerState from '@/hooks/context/useDrawerState'
import useGrantApplicationState from '@/hooks/context/useGrantApplicationState'

import { companyInfoPrompt } from '@/utils/prompts'

import StepHeader from '../StepHeader'
import ImportBody from '../../Admin/Modals/FileHub/ImportBody'

interface UploadDocumentStepProps {
  getAnswerWrapper: (question: string, streaming: boolean) => Promise<void>
  goBack: () => void
}

const UploadDocumentStep: React.FC<UploadDocumentStepProps> = ({
  getAnswerWrapper,
  goBack,
}) => {
  const [form] = Form.useForm()
  const { setCurrentStep, currentStep, steps } = useGrantApplicationState()
  const { selectedConversation } = useDrawerState()

  const finish = async () => {
    setCurrentStep(currentStep + 1)
    await getAnswerWrapper(companyInfoPrompt(steps[0]), true)
  }

  return (
    <div className='m-auto flex min-h-full w-full flex-col gap-4 rounded-lg bg-surface p-6 text-left text-on-surface sm:max-w-[70em] dark:bg-dark-surface dark:text-dark-on-surface'>
      <StepHeader
        title='Add docs to your knowledge base'
        description='Add documents here to give the AI more information to match you with grants, and draft the proposal.'
        tooltip='These documents will be stored in your dashboard. You can add/edit your knowledge base as needed. This is your repository of information relevant to your proposal.'
      />
      <div className='flex h-full grow flex-col justify-between gap-6'>
        <ImportBody conversationId={selectedConversation} />
        <Form
          form={form}
          onFinish={finish}
          autoComplete='off'
          className='flex h-full flex-col text-left'
          requiredMark='optional'
          layout='vertical'
        >
          <div className='flex justify-between gap-4'>
            <Button onClick={goBack}>Back</Button>
            <Form.Item className='m-0'>
              <Button
                className='flex items-center'
                icon={<RiSparklingFill className='size-5' />}
                htmlType='submit'
                type='primary'
              >
                Next
              </Button>
            </Form.Item>
          </div>
        </Form>
      </div>
    </div>
  )
}

export default UploadDocumentStep
