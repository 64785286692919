import { Dispatch, SetStateAction } from 'react'

import { Section, Subsection } from '@/types/document'

const updateGraphsOfSection = (
  setSections: Dispatch<
    SetStateAction<{
      sections?: Section[]
      history: Section[][]
      historyIndex: number
    }>
  >,
  setSteps: Dispatch<SetStateAction<{ [key: string]: any }>>,
  isSubsection: false | true | undefined,
  section: Section | Subsection,
  graph?: string,
  graphIndex?: number
) => {
  const newGraph = graph === undefined ? [] : [graph]
  setSections(({ sections, history, historyIndex }) => {
    const newSections = sections?.map((s) => {
      if (isSubsection) {
        const newSubsections = s.subsections?.map((sub) => {
          if (sub.id === section.id) {
            return {
              ...sub,
              graph:
                sub.graph === undefined
                  ? newGraph
                  : graphIndex !== undefined
                    ? sub.graph.map((g, i) =>
                        i === graphIndex ? graph ?? '' : g
                      )
                    : [...sub.graph, ...newGraph],
            }
          }
          return sub
        })
        return {
          ...s,
          subsections: newSubsections,
        }
      } else {
        if (s.id === section.id) {
          return {
            ...s,
            graph:
              s.graph === undefined
                ? newGraph
                : graphIndex !== undefined
                  ? s.graph.map((g, i) => (i === graphIndex ? graph ?? '' : g))
                  : [...s.graph, ...newGraph],
          }
        }
        return s
      }
    })
    let newHistory = history
    if (newSections) {
      newHistory = [
        ...(history?.slice(0, (historyIndex ?? 0) + 1) ?? []),
        newSections,
      ]
    }
    const newHistoryIndex = newHistory.length - 1
    setSteps((prevSteps) => ({
      ...prevSteps,
      8: {
        ...prevSteps[8],
        sections: newSections,
      },
    }))
    return {
      sections: newSections,
      history: newHistory,
      historyIndex: newHistoryIndex,
    }
  })
}

export default updateGraphsOfSection
