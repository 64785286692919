'use client'

import {
  RiAddLine,
  RiCloseLine,
  RiDeleteBinLine,
  RiPencilLine,
  RiSaveLine,
} from '@remixicon/react'
import { Button, Popconfirm, Tooltip } from 'antd'

import useGrantApplicationState from '@/hooks/context/useGrantApplicationState'

import GraphButton from '@/components/Matcher/GrantEditor/GraphButton'

import { generateUUID } from '@/utils'

import EnhanceButton from './EnhanceButton'

import { Section, Subsection } from '@/types/document'

interface ActionButtonsProps {
  section: Section | Subsection
  isSubsection?: boolean
  editing?: boolean
  enhancing?: boolean
  setEnhancing: (value?: boolean) => void
  setEditing: (value?: boolean) => void
  setEditingSubsection?: (subsectionId: string, value?: boolean) => void
  title?: string
  text?: string
  setText: (value: string) => void
  setTitle: (value: string) => void
}

const ActionButtons: React.FC<ActionButtonsProps> = ({
  section,
  isSubsection,
  editing,
  enhancing,
  setEnhancing,
  setEditing,
  setEditingSubsection,
  title,
  text,
  setText,
  setTitle,
}) => {
  const { sections, setSections, steps, setSteps } = useGrantApplicationState()

  const handleDelete = () => {
    setSections(
      ({ sections: prevSections, history: prevHistory, historyIndex }) => {
        let newSections = prevSections
        if (isSubsection) {
          newSections = prevSections?.map((s) => {
            const newSubsections = s.subsections?.filter(
              (subsection) => subsection.id !== section.id
            )
            return {
              ...s,
              subsections: newSubsections,
            }
          })
        } else {
          newSections = prevSections?.filter((s) => s.id !== section.id)
        }

        let newHistory = prevHistory
        if (newSections) {
          newHistory = [...prevHistory.slice(0, historyIndex + 1), newSections]
        }
        setSteps({
          ...steps,
          8: {
            ...steps[8],
            sections: newSections,
          },
        })
        return {
          sections: newSections,
          history: newHistory,
          historyIndex: newHistory.length - 1,
        }
      }
    )
  }

  const toggleEdit = () => {
    if (editing) {
      setSections(
        ({ sections: prevSections, history: prevHistory, historyIndex }) => {
          const newSections = prevSections?.map((s) => {
            if (isSubsection) {
              const newSubsections = s.subsections?.map((sub) => {
                if (sub.id === section.id) {
                  return {
                    ...sub,
                    title: title ?? '',
                    text: text ?? '',
                  }
                }
                return sub
              })
              return {
                ...s,
                subsections: newSubsections,
              }
            } else {
              if (s.id === section.id) {
                return {
                  ...s,
                  title: title ?? '',
                  text: text ?? '',
                }
              }
              return s
            }
          })
          let newHistory = prevHistory
          if (newSections) {
            newHistory = [
              ...prevHistory.slice(0, historyIndex + 1),
              newSections,
            ]
          }
          setSteps({
            ...steps,
            8: {
              ...steps[8],
              sections: newSections,
            },
          })
          return {
            sections: newSections,
            history: newHistory,
            historyIndex: newHistory.length - 1,
          }
        }
      )
    }
    setEditing(!editing)
  }

  const cancelEditing = () => {
    setEditing(false)
    setTitle(section.title)
    setText(section.text)
  }

  const addSubsection = (sectionId: string) => {
    if (!sections) {
      return
    }
    const id = generateUUID()
    setSections(
      ({ sections: prevSections, history: prevHistory, historyIndex }) => {
        const newSections = prevSections?.map((s) => {
          if (s.id === sectionId) {
            const newSubsections = [
              {
                id,
                title: '',
                text: '',
              },
            ].concat(s.subsections ?? [])
            return {
              ...section,
              subsections: newSubsections,
            }
          }
          return s
        })
        let newHistory = prevHistory
        if (newSections) {
          newHistory = [...prevHistory.slice(0, historyIndex + 1), newSections]
        }
        setSteps({
          ...steps,
          8: {
            ...steps[8],
            sections: newSections,
          },
        })
        return {
          sections: newSections,
          history: newHistory,
          historyIndex: newHistory.length - 1,
        }
      }
    )
    setEditingSubsection?.(id, true)
  }

  return (
    <div className='flex gap-1'>
      {editing ? (
        <>
          <Button
            icon={<RiSaveLine className='size-5' />}
            onClick={toggleEdit}
            type='primary'
          >
            Save
          </Button>
          <Button
            icon={<RiCloseLine className='size-5' />}
            onClick={cancelEditing}
            type='primary'
          >
            Cancel
          </Button>
        </>
      ) : (
        <>
          <EnhanceButton
            section={section}
            enhancing={enhancing}
            setEnhancing={setEnhancing}
          />
          <GraphButton section={section} isSubsection={isSubsection} />
          <Tooltip title='Edit'>
            <Button
              icon={<RiPencilLine className='size-5' />}
              onClick={toggleEdit}
              type='text'
            />
          </Tooltip>
          {!isSubsection && (
            <Tooltip title='Add subsection'>
              <Button
                icon={<RiAddLine className='size-5' />}
                onClick={() => addSubsection(section.id)}
                type='text'
              />
            </Tooltip>
          )}
          <Tooltip title='Delete'>
            <Popconfirm
              trigger='click'
              title={`Are you sure you want to delete this ${isSubsection ? 'subsection' : 'section'}?`}
              okText='Yes'
              cancelText='No'
              onConfirm={handleDelete}
            >
              <Button
                icon={<RiDeleteBinLine className='size-5' />}
                type='text'
              />
            </Popconfirm>
          </Tooltip>
        </>
      )}
    </div>
  )
}

export default ActionButtons
