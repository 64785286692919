import { cn } from '@/utils/clsx'

interface ProgressDotsProps {
  currentStep: number
  totalSteps: number
}

const ProgressDots: React.FC<ProgressDotsProps> = ({
  currentStep,
  totalSteps,
}) => {
  return (
    <div className='flex items-center gap-1 sm:gap-2'>
      {Array.from({
        length: totalSteps,
      }).map((_, index) => (
        <div
          key={index}
          className={cn(
            'w-2 sm:w-4 h-1 sm:h-2 bg-on-surface dark:bg-dark-on-surface rounded-full transition-all',
            index <= currentStep
              ? 'bg-on-surface dark:bg-dark-on-surface'
              : 'bg-on-surface/20 dark:bg-dark-on-surface/20'
          )}
        />
      ))}
    </div>
  )
}

export default ProgressDots
