'use client'

import { Button, Progress } from 'antd'
import { useEffect, useState } from 'react'
import Lottie from 'react-lottie'

import ProgressLoader from '@/components/ProgressLoader'

interface LoadingScreenProps {
  lottieAnimation: any
  text?: string | string[]
  exception?: boolean
  goBack?: () => void
  retry?: () => void
  timeInterval?: number
  finishedSteps?: number
  allSteps?: number
  infiniteLoader?: boolean
}

const LoadingScreen: React.FC<LoadingScreenProps> = ({
  lottieAnimation,
  text,
  exception,
  goBack,
  retry,
  timeInterval,
  finishedSteps,
  allSteps,
  infiniteLoader,
}) => {
  const [percent, setPercent] = useState<number>(3)
  const [status, setStatus] = useState(
    typeof text === 'string'
      ? text
      : Array.isArray(text) && text.length > 0
        ? text[0]
        : ''
  )
  const [textInterval, setTextInterval] = useState<NodeJS.Timeout>()

  useEffect(() => {
    if (text && !textInterval) {
      if (Array.isArray(text)) {
        let i = 0
        const updateStatus = () => {
          setStatus(text[i])
          if (i === text.length - 1) {
            clearInterval(textInterval)
            return
          }
          i++
        }
        updateStatus()
        setTextInterval(setInterval(updateStatus, timeInterval ?? 5000))
      } else {
        setStatus(text)
      }
    }
  }, [text])

  useEffect(() => {
    if (!finishedSteps || !allSteps) {
      return
    }

    if (finishedSteps === 0) {
      setPercent(3)
      return
    } else if (finishedSteps === allSteps) {
      setPercent(100)
      return
    }

    // Calculate the target percent with a slight overshoot to indicate progression beyond the current step
    const overshoot = 3 // Adjust the overshoot value as needed
    const targetPercent = finishedSteps * (100 / allSteps) + overshoot
    const interval = setInterval(() => {
      setPercent((p) => {
        // Calculate the difference between the target (with overshoot) and the current progress
        const diff = targetPercent - p
        // Adjust the progress, moving slower as it gets closer to the target
        return p + diff / 15 // Adjust the divisor to control the "slowness"
      })
    }, 100)
    return () => clearInterval(interval)
  }, [finishedSteps, allSteps])

  return (
    <div className='flex h-full grow flex-col items-center justify-center gap-1'>
      <div className='pointer-events-none max-w-[500px] grow content-center'>
        <Lottie
          options={{ animationData: lottieAnimation }}
          isStopped={exception}
          isClickToPauseDisabled
        />
      </div>
      <div className='flex w-full flex-col gap-2 text-center'>
        {infiniteLoader ? (
          <ProgressLoader className='!relative !h-2 rounded-lg' />
        ) : (
          <Progress
            percent={exception ? 100 : percent}
            showInfo={false}
            status={exception ? 'exception' : 'active'}
          />
        )}
        {exception ? (
          <div className='flex min-h-8 w-full items-center justify-between gap-2'>
            <Button onClick={goBack}>Back</Button>
            <div>An error occurred while analyzing grants.</div>
            {retry && (
              <Button onClick={retry} type='primary'>
                Retry
              </Button>
            )}
          </div>
        ) : (
          <div className='flex min-h-8 w-full items-center justify-center'>
            {status}
          </div>
        )}
      </div>
    </div>
  )
}

export default LoadingScreen
