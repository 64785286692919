export const getGrantsPrompt = (
  companyDetails?: { [x: string]: string },
  extraCompanyInfo?: string,
  projectDetails?: { [x: string]: string },
  preferredGrants?: string,
  protectedGroup?: { identity: string[]; additionalInfo?: string },
  additionalInfo?: string
) =>
  `${
    companyDetails
      ? `<IMPORTANT>My company details:
      Company URL: ${companyDetails?.companyUrl}
      ${companyDetails.address ? `Company address: ${companyDetails.address}` : ''}
      ${companyDetails.numberOfEmployees ? `Number of employees: ${companyDetails.numberOfEmployees}` : ''}
      ${companyDetails.annualRevenue ? `Annual revenue: ${companyDetails.annualRevenue}` : ''}
      ${companyDetails.additionalInfo || extraCompanyInfo ? `Additional company information: ${companyDetails.additionalInfo}\n${extraCompanyInfo}` : ''}
      </IMPORTANT>`
      : ''
  }
  ${
    projectDetails
      ? `<IMPORTANT>My specific project details:
      ${projectDetails.budget ? `Project budget: ${projectDetails.budget}` : ''}
      ${projectDetails.timeline ? `Project timeline: ${projectDetails.timeline}` : ''}
      ${projectDetails.previousGrants ? `Previous grants: ${projectDetails.previousGrants}` : ''}
      ${projectDetails.additionalInfo ? `Additional information: ${projectDetails.additionalInfo}` : ''}
      </IMPORTANT>`
      : ''
  }
  ${preferredGrants ? `<IMPORTANT>You must focus on finding the grants that fit this description or type: ${preferredGrants}</IMPORTANT>` : ''}
  ${protectedGroup?.identity ? `<IMPORTANT>Protected Group: ${protectedGroup.identity?.join(',')}</IMPORTANT>` : ''}
  ${protectedGroup?.additionalInfo ? `<IMPORTANT>Additional info regarding my protected group: ${protectedGroup.additionalInfo}</IMPORTANT>` : ''}
  ${additionalInfo ? `<IMPORTANT>${additionalInfo}</IMPORTANT>` : ''}`
