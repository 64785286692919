'use client'

import { RiArrowDownSFill } from '@remixicon/react'
import { Button } from 'antd'

import { cn } from '@/utils/clsx'

interface CollapseButtonProps {
  collapsed?: boolean
  setCollapsed: (value?: boolean) => void
}

const CollapseButton: React.FC<CollapseButtonProps> = ({
  collapsed,
  setCollapsed,
}) => {
  const toggleCollapse = () => {
    setCollapsed(!collapsed)
  }

  return (
    <Button
      icon={
        <RiArrowDownSFill
          className={cn('size-5 transition-all', collapsed && 'rotate-90')}
        />
      }
      onClick={toggleCollapse}
      type='text'
    />
  )
}

export default CollapseButton
