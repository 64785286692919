'use client'

import { Button, Form, Input } from 'antd'

import useGrantApplicationState from '@/hooks/context/useGrantApplicationState'

import StepHeader from '../StepHeader'

interface CompanyInfoStepProps {
  goBack: () => void
}

const CompanyInfoStep: React.FC<CompanyInfoStepProps> = ({ goBack }) => {
  const [form] = Form.useForm()
  const { setCurrentStep, currentStep, steps, setSteps } =
    useGrantApplicationState()

  const finish = async (values: { [x: string]: string }) => {
    setCurrentStep(currentStep + 1)
    setSteps({
      ...steps,
      [currentStep]: values,
    })
  }

  return (
    <div className='m-auto flex min-h-full w-full flex-col gap-6 rounded-lg bg-surface p-6 text-left text-on-surface sm:max-w-[70em] dark:bg-dark-surface dark:text-dark-on-surface'>
      <StepHeader
        title='Company Information'
        description='Enter the company URL to help us find the best grants. Add more details below to find additional grants.'
        tooltip="To match you with the best grants, we need some information about the company. At a minimum, please provide the URL of the company's website. In the fields below, you can add additional information to enhance your search. Your responses are confidential."
      />
      <Form
        form={form}
        onFinish={finish}
        autoComplete='off'
        className='flex h-full grow flex-col justify-between gap-6'
        requiredMark='optional'
        layout='vertical'
        initialValues={steps[currentStep]}
      >
        <div className='flex flex-col'>
          <Form.Item
            label='Company URL'
            name='companyUrl'
            rules={[{ type: 'string', required: true }]}
          >
            <Input placeholder='Company URL' />
          </Form.Item>
          <Form.Item
            label='Address'
            name='address'
            rules={[{ type: 'string' }]}
          >
            <Input placeholder='Address' />
          </Form.Item>
          <Form.Item
            label='Number of employees'
            name='numberOfEmployees'
            rules={[{ type: 'string' }]}
          >
            <Input placeholder='Number of employees' />
          </Form.Item>
          <Form.Item
            label='Annual revenue'
            name='annualRevenue'
            rules={[{ type: 'string' }]}
          >
            <Input placeholder='Annual revenue' />
          </Form.Item>
          <Form.Item
            label='Additional information'
            name='additionalInfo'
            rules={[{ type: 'string' }]}
          >
            <Input.TextArea
              rows={4}
              placeholder='Add additional information about the company here.'
            />
          </Form.Item>
        </div>

        <div className='flex justify-between gap-2'>
          <Button onClick={goBack}>Back</Button>
          <Form.Item className='m-0'>
            <Button htmlType='submit' type='primary'>
              Next
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  )
}

export default CompanyInfoStep
