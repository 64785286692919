'use client'

import { RiBook2Line, RiSparklingFill } from '@remixicon/react'
import { Button, Form, message } from 'antd'
import { useEffect } from 'react'

import useGrantApplicationState from '@/hooks/context/useGrantApplicationState'
import useModalsState from '@/hooks/context/useModalsState'

import ReferencesModal from '@/components/Chatbot/Output/Answer/ReferencesModal'

import { markdown } from '@/utils'
import { generateProposalDraftPrompt } from '@/utils/prompts'

import LoadingScreen from '../LoadingScreen'
import StepHeader from '../StepHeader'
import lottieSearchingAnimation from '../../../../public/lottieSearching.json'

interface GrantInfoStepProps {
  getAnswerWrapper: (question: string, streaming: boolean) => Promise<void>
  loading?: boolean
  goBack: () => void
}

const GrantInfoStep: React.FC<GrantInfoStepProps> = ({
  getAnswerWrapper,
  loading,
  goBack,
}) => {
  const [form] = Form.useForm()
  const { questions, steps, setCurrentStep, currentStep, setSteps } =
    useGrantApplicationState()
  const { toggleModal } = useModalsState()

  useEffect(() => {
    setSteps({
      ...steps,
      [currentStep]: {
        numQuestions: 1,
      },
    })
  }, [loading])

  const finish = async () => {
    setCurrentStep(currentStep + 1)
    setSteps({
      ...steps,
      [currentStep + 1]: {
        numQuestions: 1,
      },
    })
    try {
      await getAnswerWrapper(generateProposalDraftPrompt(), false)
    } catch (error) {
      console.error(error)
      message.error('Failed to generate proposal draft. Please try again.')
      goBack()
    }
  }

  return (
    <div className='relative m-auto flex min-h-full w-full flex-col gap-4 rounded-lg bg-surface p-6 text-on-surface sm:max-w-[70em] dark:bg-dark-surface dark:text-dark-on-surface'>
      <StepHeader
        title={
          questions[questions.length - 1]?.messages[1]?.message
            ? 'Selected grant alignment'
            : undefined
        }
      />
      {questions[questions.length - 1]?.messages[1]?.message ? (
        <Form
          form={form}
          onFinish={finish}
          autoComplete='off'
          className='flex h-full grow flex-col'
          labelAlign='left'
          id='scroller'
        >
          <div className='flex h-full grow flex-col gap-6'>
            <div className='markdown-answer h-full grow break-words text-left text-sm sm:text-base'>
              {markdown(
                questions[questions.length - 1]?.messages[1]?.message as string,
                loading
              )}
            </div>

            {!loading && (
              <>
                <div className='flex justify-between gap-2'>
                  <Button disabled={loading} onClick={goBack}>
                    Back
                  </Button>
                  <Button
                    disabled={loading}
                    onClick={() => toggleModal('references')}
                    icon={<RiBook2Line className='size-5' />}
                  >
                    <span className='!hidden sm:!block'>References</span>
                  </Button>
                  <Form.Item className='m-0'>
                    <Button
                      className='flex items-center'
                      htmlType='submit'
                      icon={<RiSparklingFill className='size-5' />}
                      disabled={loading}
                      type='primary'
                    >
                      Next
                    </Button>
                  </Form.Item>
                </div>
                <ReferencesModal
                  documents={
                    questions[questions.length - 1]?.messages[1]?.documents ??
                    []
                  }
                />
              </>
            )}
          </div>
          <div id='anchor' className='opacity-0'>
            a
          </div>
        </Form>
      ) : (
        <LoadingScreen
          lottieAnimation={lottieSearchingAnimation}
          text={[
            'Identifying the selected grant for detailed analysis...',
            'Gathering specific data on funding eligibility and requirements...',
            'Analyzing grant terms to ensure alignment with your needs...',
            'Compiling relevant insights...',
            'Finalizing data for selected grant...',
          ]}
          timeInterval={10000}
          infiniteLoader
        />
      )}
    </div>
  )
}

export default GrantInfoStep
