'use client'

import {
  RiArrowLeftSLine,
  RiArrowRightSLine,
  RiRefreshLine,
} from '@remixicon/react'
import { FloatButton } from 'antd'
import { useState } from 'react'

import useGrantApplicationState from '@/hooks/context/useGrantApplicationState'

interface GoogleDocsViewerProps {
  documentUrl?: string
  setDocumentUrl: (documentUrl?: string) => void
  setGeneratingDocument: (generatingDocument: boolean) => void
  getAnswerWrapper?: (question: string, streaming: boolean) => Promise<void>
}

const GoogleDocsViewer: React.FC<GoogleDocsViewerProps> = ({
  documentUrl,
  setDocumentUrl,
  setGeneratingDocument,
  getAnswerWrapper,
}) => {
  const { setCurrentStep, currentStep, steps, sections, setSteps } =
    useGrantApplicationState()
  const [iframeKey, setIframeKey] = useState(false)

  const finish = async () => {
    setSteps({
      ...steps,
      [currentStep + 1]: {
        numQuestions: 1,
      },
    })
    setCurrentStep(currentStep + 1)
    if (getAnswerWrapper) {
      await getAnswerWrapper(
        `Final document by sections: ${sections
          ?.map(
            (s) =>
              `${s.title}\n${s.text}\n${(s.subsections?.map((sub) => `${sub.title}\n${sub.text}\n`) ?? []).join('\n')}`
          )
          .join('\n')}`,
        false
      )
    }
  }

  return (
    <div className='m-auto size-full sm:max-w-[100em]'>
      <iframe
        key={iframeKey.toString()}
        src={documentUrl}
        className='m-auto size-full max-w-[100rem]'
      />
      <FloatButton.Group shape='square'>
        <FloatButton
          icon={<RiRefreshLine className='size-5' />}
          className='flex items-center'
          onClick={() => setIframeKey(!iframeKey)}
          tooltip={<div>Refresh editor</div>}
        />
        <FloatButton
          icon={<RiArrowLeftSLine className='size-5' />}
          className='flex items-center'
          onClick={() => {
            setDocumentUrl(undefined)
            setGeneratingDocument(false)
            setSteps({
              ...steps,
              [currentStep]: {
                ...steps[currentStep],
                documentUrl: undefined,
              },
            })
          }}
          tooltip={<div>Back</div>}
        />
        <FloatButton
          type='primary'
          icon={<RiArrowRightSLine className='size-5' />}
          className='flex items-center'
          onClick={finish}
          tooltip={<div>Finish</div>}
        />
      </FloatButton.Group>
    </div>
  )
}

export default GoogleDocsViewer
