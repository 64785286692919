'use client'

import { RiInformationLine } from '@remixicon/react'
import { Tooltip } from 'antd'

import useGrantApplicationState from '@/hooks/context/useGrantApplicationState'

import ProgressDots from '@/components/ProgressDots'

import { cn } from '@/utils/clsx'

import { GrantApplicationMode } from '@/types/grants'

interface StepHeaderProps {
  title?: string
  description?: string
  tooltip?: string
}

const StepHeader: React.FC<StepHeaderProps> = ({
  title,
  description,
  tooltip,
}) => {
  const { currentStep, mode } = useGrantApplicationState()

  const totalSteps =
    mode === GrantApplicationMode.MATCHING
      ? 7
      : mode === GrantApplicationMode.DRAFTING
        ? 10
        : 3

  return (
    <div className='flex flex-col gap-2'>
      <div
        className={cn(
          'flex items-center gap-2',
          title || tooltip ? 'justify-between' : 'justify-end'
        )}
      >
        <div className='flex items-center gap-2 text-left'>
          {title && <h2 className='text-xl font-bold'>{title}</h2>}
          {tooltip && (
            <Tooltip title={tooltip}>
              <RiInformationLine className='size-5' />
            </Tooltip>
          )}
        </div>
        <ProgressDots
          currentStep={
            mode === GrantApplicationMode.CONTINUE_EXISTING
              ? currentStep - 7
              : currentStep
          }
          totalSteps={totalSteps}
        />
      </div>
      {description && <p>{description}</p>}
    </div>
  )
}

export default StepHeader
