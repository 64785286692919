'use client'

import { RiChatPollLine, RiContractLine, RiSearchLine } from '@remixicon/react'
import { Divider, Tooltip } from 'antd'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Lottie from 'react-lottie'

import useDrawerState from '@/hooks/context/useDrawerState'
import useGrantApplicationState from '@/hooks/context/useGrantApplicationState'
import useAgents from '@/hooks/useAgents'
import useAuth from '@/hooks/useAuth'

import CompanyInfoStep from '@/components/Matcher/Steps/CompanyInfoStep'
import FeedbackStep from '@/components/Matcher/Steps/FeedbackStep'
import ProtectedGroupStep from '@/components/Matcher/Steps/ProtectedGroupStep'
import SpecificProjectStep from '@/components/Matcher/Steps/SpecificProjectStep'

import { configHeader } from '@/constants/api'
import { API_URL } from '@/constants/env'
import { getAnswer, getDrafts } from '@/service/Chatbot'
import { generateUUID } from '@/utils'
import { getGrantsPrompt } from '@/utils/prompts'

import GrantEditor from './GrantEditor'
import AgentResponseStep from './Steps/AgentResponseStep'
import GrantInfoStep from './Steps/GrantInfoStep'
import PreferredGrantsStep from './Steps/PreferredGrantsStep'
import SelectGrantStep from './Steps/SelectGrantStep'
import UploadDocumentStep from './Steps/UploadDocumentStep'
import UploadGrantStep from './Steps/UploadGrantStep'
import lottieChatLoadingScreenAnimation from '../../../public/lottieChatLoadingScreen.json'

import { IQuestion } from '@/types/chatbot'
import { GrantApplicationMode } from '@/types/grants'

const Matcher: React.FC = () => {
  const { agents, selectedAgent } = useAgents()
  const { user } = useAuth()
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()
  const [finishedSteps, setFinishedSteps] = useState(0)
  const [progressException, setProgressException] = useState(false)
  const { selectedConversation, setSelectedConversation, mutateConversations } =
    useDrawerState()
  const {
    currentStep,
    questions,
    steps,
    mode,
    setQuestions,
    setCurrentStep,
    setMode,
    setSections,
    setSteps,
  } = useGrantApplicationState()

  const gatherGrants = async (
    additionalInfo?: string,
    tmpQuestions?: IQuestion[]
  ) => {
    setLoading(true)
    await getDraftsWrapper(
      getGrantsPrompt(
        steps[0],
        steps[2]?.additionalInfo,
        steps[4],
        steps[5]?.preferredGrants,
        steps[3],
        additionalInfo
      ),
      tmpQuestions
    )
  }

  const updateConversationTitle = async () => {
    const config = {
      method: 'put',
      withCredentials: true,
      ...configHeader,
      data: JSON.stringify({
        titlePrompt: `Title should include the company name or any potential grant information.`,
      }),
    }

    try {
      await axios(`${API_URL}/v2/conversations/${selectedConversation}`, config)
      mutateConversations()
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (
      !loading &&
      (currentStep === 2 || currentStep === 6 || currentStep === 7)
    ) {
      updateConversationTitle()
    }
  }, [loading])

  const reset = () => {
    setCurrentStep(-1)
    setFinishedSteps(0)
    setProgressException(false)
    setQuestions([])
    setMode(undefined)
    setSelectedConversation(undefined)
  }

  const retry = async (additionalInfo: string) => {
    setProgressException(false)
    setFinishedSteps(0)
    let newQuestions = questions
    if (steps[currentStep]?.numQuestions) {
      newQuestions = questions.slice(0, -steps[currentStep].numQuestions)
    }
    setQuestions(newQuestions)
    await gatherGrants(additionalInfo, newQuestions)
  }

  const getAnswerWrapper = async (question: string, streaming: boolean) => {
    if (!selectedAgent || !agents || !setQuestions) return
    await getAnswer(
      true,
      question,
      selectedAgent.id,
      questions,
      agents,
      setLoading,
      setQuestions,
      t,
      false,
      user?.email,
      true,
      selectedConversation,
      streaming
    )
  }

  const getDraftsWrapper = async (
    question: string,
    tmpQuestions?: IQuestion[]
  ) => {
    if (!selectedAgent || !agents) return
    await getDrafts(
      true,
      question,
      selectedAgent.id,
      tmpQuestions ?? questions,
      agents,
      setLoading,
      setQuestions,
      t,
      setProgressException,
      setFinishedSteps,
      user?.email,
      selectedConversation
    )
  }

  const goBack = () => {
    if (steps[currentStep]?.numQuestions) {
      setQuestions(questions.slice(0, -steps[currentStep].numQuestions))
    }
    setSteps({
      ...steps,
      [currentStep]: undefined,
    })

    if (
      currentStep === 0 ||
      (currentStep === 7 && mode === GrantApplicationMode.CONTINUE_EXISTING)
    ) {
      reset()
    } else {
      if (currentStep === 6) {
        setFinishedSteps(0)
        setProgressException(false)
      } else if (currentStep === 8) {
        setSections({
          sections: [],
          history: [],
          historyIndex: 0,
        })
      }
      setCurrentStep(currentStep - 1)
    }
  }

  if (
    mode === GrantApplicationMode.DRAFTING ||
    mode === GrantApplicationMode.MATCHING
  ) {
    return (
      <div className='flex size-full overflow-y-auto py-2 sm:py-4'>
        {currentStep === 0 ? (
          <CompanyInfoStep goBack={goBack} />
        ) : currentStep === 1 ? (
          <UploadDocumentStep
            getAnswerWrapper={getAnswerWrapper}
            goBack={goBack}
          />
        ) : currentStep === 2 ? (
          <AgentResponseStep
            getAnswerWrapper={getAnswerWrapper}
            loading={loading}
            goBack={goBack}
          />
        ) : currentStep === 3 ? (
          <ProtectedGroupStep goBack={goBack} />
        ) : currentStep === 4 ? (
          <SpecificProjectStep goBack={goBack} />
        ) : currentStep === 5 ? (
          <PreferredGrantsStep gatherGrants={gatherGrants} goBack={goBack} />
        ) : currentStep === 6 ? (
          <SelectGrantStep
            getAnswerWrapper={getAnswerWrapper}
            loading={loading}
            setLoading={setLoading}
            retry={retry}
            progressException={progressException}
            setProgressException={setProgressException}
            finishedSteps={finishedSteps}
            setFinishedSteps={setFinishedSteps}
            goBack={goBack}
          />
        ) : currentStep === 7 ? (
          <GrantInfoStep
            getAnswerWrapper={getAnswerWrapper}
            loading={loading}
            goBack={goBack}
          />
        ) : currentStep === 8 ? (
          <GrantEditor loading={loading} goBack={goBack} />
        ) : currentStep === 9 ? (
          <FeedbackStep
            getAnswerWrapper={getAnswerWrapper}
            loading={loading}
            goBack={goBack}
          />
        ) : currentStep === 10 ? (
          <div className='pointer-events-none m-auto size-full max-w-[300px] opacity-80'>
            <Lottie
              options={{ animationData: lottieChatLoadingScreenAnimation }}
            />
          </div>
        ) : null}
      </div>
    )
  }

  if (mode === GrantApplicationMode.CONTINUE_EXISTING) {
    return (
      <div className='flex size-full overflow-y-auto py-2 sm:py-4'>
        {currentStep === 7 ? (
          <UploadGrantStep
            mutateConversations={mutateConversations}
            setLoading={setLoading}
            goBack={goBack}
          />
        ) : currentStep === 8 ? (
          <GrantEditor
            getAnswerWrapper={getAnswerWrapper}
            loading={loading}
            goBack={goBack}
          />
        ) : currentStep === 9 ? (
          <FeedbackStep
            getAnswerWrapper={getAnswerWrapper}
            loading={loading}
            goBack={goBack}
          />
        ) : currentStep === 10 ? (
          <div className='pointer-events-none m-auto size-full max-w-[300px] opacity-80'>
            <Lottie
              options={{ animationData: lottieChatLoadingScreenAnimation }}
            />
          </div>
        ) : null}
      </div>
    )
  }

  return (
    <div className='flex size-full overflow-y-auto'>
      <div className='m-auto flex flex-col gap-12'>
        <div
          className='relative flex cursor-pointer flex-col items-center gap-2'
          onClick={() => {
            setMode(GrantApplicationMode.DRAFTING)
            setCurrentStep(0)
            setSelectedConversation(generateUUID())
          }}
        >
          <Tooltip
            title="I'm ready to find, match and write a grant from start to finish. This is your all-in-one grant assistant."
            placement='right'
            className='flex flex-col items-center gap-4'
          >
            <div className='flex !size-52 items-center justify-center overflow-hidden rounded-3xl bg-primary/30 p-4 transition-all hover:scale-105 dark:bg-dark-primary/30'>
              <div className='relative left-2 top-3 m-auto scale-[170%]'>
                <Lottie
                  options={{ animationData: lottieChatLoadingScreenAnimation }}
                />
              </div>
            </div>
            <p className='text-center text-lg font-semibold'>GrantAI</p>
          </Tooltip>
        </div>

        <div className='flex flex-col gap-5'>
          <Divider className='!text-sm !font-normal opacity-70'>
            Other apps
          </Divider>
          <div className='grid grid-cols-3 gap-1 self-center sm:gap-6'>
            <Tooltip title='I want to find matching grants'>
              <div
                className='flex cursor-pointer flex-col items-center gap-3'
                onClick={() => {
                  setMode(GrantApplicationMode.MATCHING)
                  setCurrentStep(0)
                  setSelectedConversation(generateUUID())
                }}
              >
                <div className='flex !size-24 items-center justify-center rounded-3xl bg-primary/30 transition-all hover:scale-110 dark:bg-dark-primary/30'>
                  <RiSearchLine className='size-16 text-primary/80 dark:text-dark-on-primary' />
                </div>
                <p className='text-center'>Find grants</p>
              </div>
            </Tooltip>
            <Tooltip title='I want to research something specific'>
              <div
                className='flex cursor-pointer flex-col items-center gap-3'
                onClick={() => {
                  setMode(GrantApplicationMode.RESEARCHING)
                  setCurrentStep(10)
                  setSelectedConversation(generateUUID())
                }}
              >
                <div className='flex !size-24 items-center justify-center rounded-3xl bg-primary/30 transition-all hover:scale-110 dark:bg-dark-primary/30'>
                  <RiChatPollLine className='size-16 text-primary/80 dark:text-dark-on-primary' />
                </div>
                <p className='text-center'>Research mode</p>
              </div>
            </Tooltip>
            <Tooltip title='I have an existing grant I want to refine'>
              <div
                className='flex cursor-pointer flex-col items-center gap-3'
                onClick={() => {
                  setMode(GrantApplicationMode.CONTINUE_EXISTING)
                  setCurrentStep(7)
                  setSelectedConversation(generateUUID())
                }}
              >
                <div className='flex !size-24 items-center justify-center rounded-3xl bg-primary/30 transition-all hover:scale-110 dark:bg-dark-primary/30'>
                  <RiContractLine className='size-16 text-primary/80 dark:text-dark-on-primary' />
                </div>
                <p className='text-center'>Redrafting assistant</p>
              </div>
            </Tooltip>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Matcher
