import { LinearProgress } from '@mui/material'

import useLoadingState from '@/hooks/context/useLoadingState'

import { cn } from '@/utils/clsx'

interface ProgressLoaderProps {
  className?: string
}

const ProgressLoader: React.FC<ProgressLoaderProps> = ({ className }) => {
  const { progress } = useLoadingState()

  return (
    <LinearProgress
      className={cn(
        'absolute z-[1000] h-0.5 w-full rounded-none bg-surface dark:bg-dark-surface',
        className
      )}
      value={progress}
    />
  )
}

export default ProgressLoader
