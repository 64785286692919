'use client'

import { RiBook2Line, RiSparklingFill } from '@remixicon/react'
import { Button, Form, Input, Popover } from 'antd'
import { useEffect, useState } from 'react'

import useGrantApplicationState from '@/hooks/context/useGrantApplicationState'
import useModalsState from '@/hooks/context/useModalsState'

import ReferencesModal from '@/components/Chatbot/Output/Answer/ReferencesModal'

import { markdown } from '@/utils'
import { enhanceCompanyInfoPrompt } from '@/utils/prompts'

import LoadingScreen from '../LoadingScreen'
import StepHeader from '../StepHeader'
import lottieSearchingAnimation from '../../../../public/lottieSearching.json'

interface AgentResponseStepProps {
  getAnswerWrapper: (question: string, streaming: boolean) => Promise<void>
  loading?: boolean
  goBack: () => void
}

const AgentResponseStep: React.FC<AgentResponseStepProps> = ({
  getAnswerWrapper,
  loading,
  goBack,
}) => {
  const [form] = Form.useForm()
  const { questions, steps, setCurrentStep, currentStep, setSteps } =
    useGrantApplicationState()
  const { toggleModal } = useModalsState()
  const [numQuestions, setNumQuestions] = useState<number>(0)
  const [enhancing, setEnhancing] = useState(false)
  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (!numQuestions && steps[currentStep]?.numQuestions) {
      setNumQuestions(steps[currentStep]?.numQuestions ?? 1)
    }
  }, [steps[currentStep]?.numQuestions])

  useEffect(() => {
    if (!loading && questions[questions.length - 1]?.messages[1]?.message) {
      const newNumQuestions = (numQuestions ?? 1) + 1
      setNumQuestions(newNumQuestions)
      setSteps({
        ...steps,
        [currentStep]: {
          numQuestions: newNumQuestions,
        },
      })
      form.setFieldValue('additionalInfo', '')
      setEnhancing(false)
    }
  }, [loading])

  const finish = async (values: { [x: string]: string }) => {
    setSteps({
      ...steps,
      [currentStep]: {
        ...steps[currentStep],
        additionalInfo: values.additionalInfo,
      },
    })
    setCurrentStep(currentStep + 1)
  }

  const enhance = async (values: { [x: string]: string }) => {
    setEnhancing(true)
    setOpen(false)
    await getAnswerWrapper(
      enhanceCompanyInfoPrompt(
        questions[questions.length - 1]?.messages[1]?.message,
        steps[0],
        values.additionalInfo
      ),
      true
    )
  }

  return (
    <div className='relative m-auto flex min-h-full w-full flex-col gap-4 rounded-lg bg-surface p-6 text-left text-on-surface sm:max-w-[70em] dark:bg-dark-surface dark:text-dark-on-surface'>
      <StepHeader
        title={
          questions[questions.length - 1]?.messages[1]?.message
            ? 'Company Summary'
            : undefined
        }
      />
      {questions[questions.length - 1]?.messages[1]?.message ? (
        <Form
          form={form}
          onFinish={finish}
          autoComplete='off'
          className='flex h-full grow flex-col'
          layout='vertical'
          requiredMark='optional'
          id='scroller'
        >
          <div className='markdown-answer grow break-words text-left text-sm sm:text-base'>
            {markdown(
              questions[questions.length - 1]?.messages[1]?.message as string,
              loading
            )}
          </div>
          {!loading && (
            <div className='mt-6 flex flex-col gap-4'>
              <Form.Item
                label='Additional information'
                name='additionalInfo'
                rules={[{ type: 'string' }]}
              >
                <Input.TextArea
                  rows={4}
                  disabled={enhancing}
                  placeholder='Add additional information here like information about company projects, objectives of the company or any other relevant company information.'
                />
              </Form.Item>

              <div className='flex justify-between gap-2'>
                <Button disabled={enhancing} onClick={goBack}>
                  Back
                </Button>
                <div className='flex gap-2'>
                  <Button
                    disabled={enhancing}
                    onClick={() => toggleModal('references')}
                    icon={<RiBook2Line className='size-5' />}
                  >
                    <span className='!hidden sm:!block'>References</span>
                  </Button>
                  <Popover
                    trigger='click'
                    className='cursor-pointer'
                    overlayClassName='w-full max-w-md'
                    open={open}
                    onOpenChange={setOpen}
                    content={
                      <Form
                        autoComplete='off'
                        className='flex w-full flex-col items-end'
                        layout='vertical'
                        onFinish={enhance}
                      >
                        <Form.Item
                          name='additionalInfo'
                          rules={[{ type: 'string' }]}
                          className='w-full'
                        >
                          <Input.TextArea
                            rows={5}
                            placeholder='How would you like to enhance the company information?'
                            className='w-full'
                          />
                        </Form.Item>
                        <Form.Item className='m-0'>
                          <Button
                            className='flex items-center'
                            htmlType='submit'
                            type='primary'
                            icon={<RiSparklingFill className='size-5' />}
                          >
                            Enhance
                          </Button>
                        </Form.Item>
                      </Form>
                    }
                  >
                    <Button
                      className='flex items-center'
                      icon={<RiSparklingFill className='size-5' />}
                    >
                      <span className='!hidden sm:!block'>Enhance</span>
                    </Button>
                  </Popover>
                </div>
                <Form.Item className='m-0'>
                  <Button
                    className='flex items-center'
                    htmlType='submit'
                    type='primary'
                    disabled={enhancing}
                  >
                    Next
                  </Button>
                </Form.Item>
              </div>
              <ReferencesModal
                documents={
                  questions[questions.length - 1]?.messages[1]?.documents ?? []
                }
              />
            </div>
          )}
          <div id='anchor' className='opacity-0'>
            a
          </div>
        </Form>
      ) : (
        <LoadingScreen
          lottieAnimation={lottieSearchingAnimation}
          text={[
            'Analyzing web sources for relevant information...',
            'Thinking...',
            'Gathering detailed company insights...',
            'Thinking...',
            'Compiling comprehensive company details...',
          ]}
          timeInterval={10000}
          infiniteLoader
        />
      )}
    </div>
  )
}

export default AgentResponseStep
