import { useContext } from 'react'

import { GrantApplicationContext } from '@/context/grantApplication/grant.context'

const useGrantApplicationState = () => {
  const context = useContext(GrantApplicationContext)
  if (!Object.keys(GrantApplicationContext).length) {
    throw new Error('GrantApplicationContext must be used within its provider')
  }
  return context
}

export default useGrantApplicationState
