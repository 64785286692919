'use client'

import {
  RiArrowGoBackLine,
  RiArrowGoForwardLine,
  RiCollapseVerticalLine,
  RiExpandVerticalLine,
  RiSparklingFill,
} from '@remixicon/react'
import { Button, FloatButton, message, Tooltip } from 'antd'
import axios from 'axios'
import { jsonrepair } from 'jsonrepair'
import { useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'

import useDrawerState from '@/hooks/context/useDrawerState'
import useGrantApplicationState from '@/hooks/context/useGrantApplicationState'
import useAgents from '@/hooks/useAgents'
import useAuth from '@/hooks/useAuth'
import useConversation from '@/hooks/useConversation'

import { configHeader } from '@/constants/api'
import { API_URL } from '@/constants/env'
import { mapConversation } from '@/service/Chatbot'
import { countWords, generateUUID } from '@/utils'
import { cn } from '@/utils/clsx'
import { enhance } from '@/utils/grant'

import AddSectionButton from './AddSectionButton'
import GoogleDocsViewer from './GoogleDocsViewer'
import SectionsDragDrop from './SectionsDragDrop'
import LoadingScreen from '../LoadingScreen'
import StepHeader from '../StepHeader'
import lottieGeneratingDocument from '../../../../public/lottieGeneratingDocument.json'
import lottieScanDocument from '../../../../public/lottieScanDocument.json'

import { Section } from '@/types/document'
import { EnhanceType, GrantApplicationMode } from '@/types/grants'

interface GrantEditorProps {
  getAnswerWrapper?: (question: string, streaming: boolean) => Promise<void>
  loading?: boolean
  goBack?: () => void
}

const GrantEditor: React.FC<GrantEditorProps> = ({
  getAnswerWrapper,
  loading,
  goBack,
}) => {
  const { user } = useAuth()
  const {
    setCurrentStep,
    currentStep,
    steps,
    questions,
    sections,
    setSections,
    setSteps,
    setQuestions,
    mode,
    isUndoDisabled,
    isRedoDisabled,
    handleUndo,
    handleRedo,
  } = useGrantApplicationState()
  const { selectedConversation } = useDrawerState()
  const { conversation } = useConversation(selectedConversation)
  const { agents, selectedAgent } = useAgents()
  const [editing, setEditing] = useState<{ [key: string]: boolean }>()
  const [enhancing, setEnhancing] = useState<{ [key: string]: boolean }>()
  const [collapsed, setCollapsed] = useState<{ [key: string]: boolean }>()
  const [documentUrl, setDocumentUrl] = useState<string>()
  const [generatingDocument, setGeneratingDocument] = useState<boolean>(false)
  const [initialSectionsStatus, setInitialSectionsStatus] = useState<
    'enhancing' | 'enhanced' | 'enhancingTwice' | 'enhancedTwice'
  >(steps[currentStep]?.initialSectionsStatus)
  const [finishedSteps, setFinishedSteps] = useState<number>(0)
  const isMobile = useMediaQuery({ query: '(max-width: 640px)' })

  useEffect(() => {
    if (
      conversation &&
      questions.length === 0 &&
      mode === GrantApplicationMode.CONTINUE_EXISTING
    ) {
      setQuestions(mapConversation(conversation, agents))
    }
  }, [conversation])

  useEffect(() => {
    setDocumentUrl(steps[currentStep]?.documentUrl)
  }, [steps[currentStep]?.documentUrl])

  useEffect(() => {
    if (sections) {
      return
    }

    if (!loading) {
      const text = questions[questions.length - 1]?.messages[1]?.message
      if (text && text !== '') {
        try {
          const array: Section[] = JSON.parse(
            jsonrepair(
              text.replaceAll('```typescript', '').replaceAll('```', '')
            )
          )
          const newSections = array?.map((s) => ({
            id: generateUUID(),
            title: s.title ?? '',
            text: s.text ?? '',
            subsections:
              s.subsections?.map(
                (subsection: { title: string; text: string }) => ({
                  id: generateUUID(),
                  title: subsection.title,
                  text: subsection.text,
                })
              ) ?? [],
          }))

          if (!newSections) {
            throw new Error('An error occurred. Please try again.')
          }

          setSteps({
            ...steps,
            [currentStep]: {
              ...steps[currentStep],
              numQuestions: 1,
            },
          })

          setSections({
            sections: newSections,
            history: [newSections],
            historyIndex: 0,
          })
        } catch (error) {
          console.error(error)
          message.error('An error occurred. Please try again.')
          setCurrentStep(currentStep - 1)
          setQuestions(questions.slice(0, -1))
        }
      }
    }
  }, [
    loading,
    questions[questions.length - 1]?.messages[1]?.message,
    steps[currentStep]?.sections,
  ])

  // 1st hidden enhancement of all sections
  useEffect(() => {
    if (
      sections &&
      !initialSectionsStatus &&
      !steps[currentStep]?.initialSectionsStatus
    ) {
      setFinishedSteps((finished) => finished + 1)
      const enhanceAllSections = async () => {
        if (sections) {
          const enhancePromises = sections.map((section) => {
            return enhance(
              setSections,
              setSteps,
              questions,
              selectedAgent.id,
              section,
              EnhanceType.DATA,
              selectedConversation,
              user,
              sections
            ).finally(() => {
              setFinishedSteps((finished) => finished + 1)
            })
          })
          setInitialSectionsStatus('enhancing')
          await Promise.all(enhancePromises)
          setInitialSectionsStatus('enhanced')
        }
      }
      enhanceAllSections()
    }
  }, [sections, initialSectionsStatus])

  // 2nd hidden enhancement of all sections
  useEffect(() => {
    if (
      initialSectionsStatus === 'enhanced' &&
      mode === GrantApplicationMode.DRAFTING
    ) {
      const enhanceAllSections = async () => {
        if (sections) {
          const enhancePromises = sections.map((section) => {
            return enhance(
              setSections,
              setSteps,
              questions,
              selectedAgent.id,
              section,
              EnhanceType.DATA,
              selectedConversation,
              user,
              sections
            ).finally(() => {
              setFinishedSteps((finished) => finished + 1)
            })
          })
          setInitialSectionsStatus('enhancingTwice')
          await Promise.all(enhancePromises)
          setInitialSectionsStatus('enhancedTwice')
          setSteps((steps: any) => ({
            ...steps,
            [currentStep]: {
              ...steps[currentStep],
              numQuestions: 1,
              initialSectionsStatus: 'enhancedTwice',
            },
          }))
        }
      }
      enhanceAllSections()
    }
    if (mode == GrantApplicationMode.CONTINUE_EXISTING) {
      setInitialSectionsStatus('enhancedTwice')
      setSteps((steps: any) => ({
        ...steps,
        [currentStep]: {
          ...steps[currentStep],
          numQuestions: 1,
          initialSectionsStatus: 'enhancedTwice',
        },
      }))
    }
  }, [sections])

  useEffect(() => {
    if (initialSectionsStatus === 'enhancedTwice') {
      setSteps((steps: any) => ({
        ...steps,
        [currentStep]: {
          ...steps[currentStep],
          numQuestions: 1,
          initialSectionsStatus: 'enhancedTwice',
        },
      }))
    }
  }, [sections])

  const generateDocument = async () => {
    setGeneratingDocument(true)
    try {
      const res = await axios(`${API_URL}/google-doc/generate-grant`, {
        method: 'post',
        withCredentials: true,
        ...configHeader,
        data: JSON.stringify({
          sections: sections,
          user: user?.email,
        }),
      })

      setSteps({
        ...steps,
        [currentStep]: {
          ...steps[currentStep],
          documentUrl: res.data.url,
        },
      })
      setDocumentUrl(res.data.url)
    } catch (error) {
      console.error(error)
      message.error('Failed to generate the grant proposal document.')
      setGeneratingDocument(false)
    }
  }

  const collapseAll = () => {
    const newCollapsed = sections?.reduce(
      (acc, section) => ({ ...acc, [section.id]: true }),
      {}
    )
    setCollapsed(newCollapsed)
  }

  if (documentUrl) {
    return (
      <GoogleDocsViewer
        documentUrl={documentUrl}
        setDocumentUrl={setDocumentUrl}
        setGeneratingDocument={setGeneratingDocument}
        getAnswerWrapper={getAnswerWrapper}
      />
    )
  }

  const handleCountWords = () => {
    let numWords = 0
    for (const section of sections ?? []) {
      const sectionsWords = countWords(`${section.title} ${section.text}`)
      const subsectionsWords = countWords(
        section.subsections?.reduce(
          (acc, subsection) => `${acc} ${subsection.title} ${subsection.text}`,
          ''
        ) ?? ''
      )
      numWords += sectionsWords + subsectionsWords
    }
    return numWords
  }

  return (
    <div className='relative m-auto flex min-h-full w-full flex-col gap-6 rounded-lg bg-surface p-6 text-left text-on-surface sm:max-w-[70em] dark:bg-dark-surface dark:text-dark-on-surface'>
      <StepHeader
        title={
          !sections ||
          loading ||
          initialSectionsStatus !== 'enhancedTwice' ||
          generatingDocument
            ? undefined
            : 'Edit grant proposal'
        }
      />

      {!sections || loading || initialSectionsStatus !== 'enhancedTwice' ? (
        <>
          {mode === GrantApplicationMode.DRAFTING ? (
            <LoadingScreen
              finishedSteps={finishedSteps}
              allSteps={(sections?.length ?? 0) * 2 + 1}
              lottieAnimation={lottieScanDocument}
              text={[
                'Reviewing grant requirements and guidelines...',
                'Collecting relevant company data for the proposal...',
                'Analyzing funding objectives to align your proposal...',
                'Crafting a compelling executive summary...',
                "Highlighting your company's key strengths and impact...",
                'Formulating clear objectives and outcomes for the project...',
                'Building a detailed budget and financial plan...',
                'Clarifying project goals and anticipated outcomes...',
                'Revising proposal sections for clarity and impact...',
                'Ensuring all documentation meets grant specifications...',
                'Compiling data on project sustainability and scalability...',
                'Ensuring compliance with all grant submission criteria...',
                'Finalizing the proposal with supporting documents...',
                'Reviewing formatting for a professional presentation...',
                'Conducting final checks for accuracy and completeness...',
                'Preparing your polished grant proposal for review...',
              ]}
              timeInterval={20000}
            />
          ) : (
            <LoadingScreen
              lottieAnimation={lottieScanDocument}
              text={[
                'Extracting content and preparing for editing...',
                'Analyzing file structure and formatting...',
                'Optimizing data for seamless editing...',
                'Loading your document into the editor...',
              ]}
              timeInterval={20000}
              infiniteLoader
            />
          )}
        </>
      ) : generatingDocument ? (
        <LoadingScreen
          lottieAnimation={lottieGeneratingDocument}
          text={[
            'Compiling all proposal sections into a single document...',
            'Formatting your grant proposal for clarity and professionalism...',
            'Incorporating supporting data and visuals...',
            'Finalizing the proposal document for review...',
            'Preparing your grant proposal for download...',
          ]}
          timeInterval={10000}
          infiniteLoader
        />
      ) : (
        <>
          <FloatButton.Group shape='square' className='right-0 sm:right-6'>
            <FloatButton
              icon={<RiExpandVerticalLine className='size-5' />}
              className='flex items-center'
              onClick={() => setCollapsed({})}
              tooltip={<div>Expand all sections</div>}
            />
            <FloatButton
              icon={<RiCollapseVerticalLine className='size-5' />}
              className='flex items-center'
              onClick={collapseAll}
              tooltip={<div>Collapse all sections</div>}
            />
            <FloatButton
              icon={
                <RiArrowGoBackLine
                  className={cn('size-5', isUndoDisabled ? 'opacity-40' : '')}
                />
              }
              onClick={handleUndo}
              className={cn(
                'flex items-center',
                isUndoDisabled
                  ? 'grayscale-[0.8] text-opacity-20 pointer-events-none'
                  : ''
              )}
              tooltip={<div>Undo</div>}
            />
            <FloatButton
              icon={
                <RiArrowGoForwardLine
                  className={cn('size-5', isRedoDisabled ? 'opacity-40' : '')}
                />
              }
              onClick={handleRedo}
              className={cn(
                'flex items-center',
                isRedoDisabled
                  ? 'grayscale-[0.8] text-opacity-20 pointer-events-none'
                  : ''
              )}
              tooltip={<div>Redo</div>}
            />
          </FloatButton.Group>
          <div className='flex w-full items-center justify-between gap-2 bg-surface text-on-surface transition-all dark:bg-dark-surface dark:text-dark-on-surface'>
            {!isMobile && (
              <div className='flex items-center gap-2'>
                <Button
                  className='w-fit self-end'
                  icon={<RiExpandVerticalLine className='size-4' />}
                  onClick={() => setCollapsed({})}
                >
                  Expand all sections
                </Button>
                <Button
                  className='w-fit self-end'
                  icon={<RiCollapseVerticalLine className='size-4' />}
                  onClick={collapseAll}
                >
                  Collapse all sections
                </Button>
              </div>
            )}
            <Tooltip
              className='self-end'
              title='Total number of words in the grant proposal draft.'
            >
              <div className='text-xs opacity-70'>
                {handleCountWords()} words
              </div>
            </Tooltip>
          </div>
          <div className='m-auto flex w-full grow flex-col justify-between gap-4'>
            <SectionsDragDrop
              enhancing={enhancing}
              setEnhancing={setEnhancing}
              editing={editing}
              setEditing={setEditing}
              collapsed={collapsed}
              setCollapsed={setCollapsed}
            />
            <div className='flex items-center justify-between gap-2'>
              <Button onClick={goBack}>Back</Button>
              <AddSectionButton setEditing={setEditing} />
              <Button
                className='w-fit self-end'
                icon={<RiSparklingFill className='size-5' />}
                type='primary'
                onClick={generateDocument}
                disabled={
                  enhancing &&
                  Object.values(enhancing).some((isEnhancing) => isEnhancing)
                }
              >
                Next
              </Button>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default GrantEditor
