import { dateFormat, datetimeFormat } from '@/branding-config'

export const grantAlignmentPrompt = (selectedGrant?: string) =>
  `${selectedGrant ? `User selected grant: ${selectedGrant}` : 'Select one grant that most closely aligns with my company.'}

Provide the real URL for the selected grant.
Then list out the criteria for successfully applying to that grant and how my company specifically meets each of these criteria being extremely specific including facts and data both about the grant and the company.
List out the typical grant award size for companies based on available data on the web, and include the deadline for applying. Use citations.
FOR DATES ALWAYS USE AMERICAN DATE FORMAT: ${dateFormat}
FOR DATE TIME ALWAYS USE AMERICAN DATE TIME FORMAT: ${datetimeFormat}
YOU MUST RESPOND IN MARKDOWN FORMAT.
USE INLINE CITATIONS IN TEXT FOR ALL FACTS, DATA, AND INFORMATION THAT IS RETRIEVED FROM CONTEXT DOCUMENTS, MESSAGE HISTORY OR WEB SEARCHES!!!
THERE MUST BE AT LEAST 1 CITATION PER PARAGRAPH.`
