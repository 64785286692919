'use client'

import { RiBook2Line, RiSearchLine, RiSparklingFill } from '@remixicon/react'
import { Button, Card, Form, Input, message, Popover } from 'antd'
import { jsonrepair } from 'jsonrepair'
import Link from 'next/link'
import { SetStateAction, useEffect, useState } from 'react'

import useGrantApplicationState from '@/hooks/context/useGrantApplicationState'
import useModalsState from '@/hooks/context/useModalsState'

import ReferencesModal from '@/components/Chatbot/Output/Answer/ReferencesModal'

import { cn } from '@/utils/clsx'
import { grantAlignmentPrompt } from '@/utils/prompts'

import LoadingScreen from '../LoadingScreen'
import StepHeader from '../StepHeader'
import lottieGrantsAnimation from '../../../../public/lottieGrants.json'

import { GrantApplicationMode } from '@/types/grants'

interface SelectGrantStepProps {
  getAnswerWrapper: (question: string, streaming: boolean) => Promise<void>
  progressException: boolean
  loading?: boolean
  setLoading: (value: SetStateAction<boolean>) => void
  retry: (additionalInfo: string) => void
  goBack: () => void
  setProgressException: (progressException: boolean) => void
  finishedSteps: number
  setFinishedSteps: (value: SetStateAction<number>) => void
}

const SelectGrantStep: React.FC<SelectGrantStepProps> = ({
  getAnswerWrapper,
  loading,
  setLoading,
  progressException,
  retry,
  goBack,
  setProgressException,
  finishedSteps,
  setFinishedSteps,
}) => {
  const [form] = Form.useForm()
  const { setCurrentStep, currentStep, steps, questions, setSteps, mode } =
    useGrantApplicationState()
  const { toggleModal } = useModalsState()
  const [open, setOpen] = useState(false)
  const [additionalInfo, setAdditionalInfo] = useState('')
  const [text, setText] = useState<string>()
  const [selectedGrant, setSelectedGrant] = useState<number>()

  useEffect(() => {
    setFinishedSteps(0)
  }, [])

  useEffect(() => {
    if (!loading) {
      try {
        JSON.parse(
          jsonrepair(
            questions[questions.length - 1]?.messages[1]?.message as string
          )
        )
        setText(questions[questions.length - 1]?.messages[1]?.message)
        setProgressException(false)
        setSteps({
          ...steps,
          [currentStep]: {
            numQuestions: 1,
          },
        })
      } catch (e) {
        message.error(
          'Error occurred while analyzing grants. Please try again.'
        )
        setText(undefined)
        setProgressException(true)
      }
    }
  }, [questions[questions.length - 1]?.messages[1]?.message, loading])

  const next = async () => {
    if (mode === GrantApplicationMode.MATCHING) {
      setCurrentStep(10)
    } else {
      setCurrentStep(currentStep + 1)
    }
    setLoading(true)

    let grant
    if (text && selectedGrant) {
      grant = JSON.parse(jsonrepair(text)).grants[selectedGrant]
    }
    await getAnswerWrapper(grantAlignmentPrompt(JSON.stringify(grant)), true)
  }

  return (
    <div className='relative m-auto flex min-h-full w-full flex-col gap-6 rounded-lg bg-surface p-6 text-left text-on-surface sm:max-w-[70em] dark:bg-dark-surface dark:text-dark-on-surface'>
      <StepHeader
        title={text && !loading ? 'Select the most relevant grant' : undefined}
        description={
          text && !loading
            ? "Select one of the grants listed below. If you don't select a grant, GrantAI will automatically choose the best fitting grant for you."
            : undefined
        }
      />
      {text && !loading ? (
        <Form
          form={form}
          onFinish={next}
          autoComplete='off'
          className='flex h-full grow flex-col'
          labelAlign='left'
          id='scroller'
        >
          <div className='flex h-full grow flex-col gap-6'>
            <div className='markdown-answer grow justify-between break-words text-left text-sm sm:text-base'>
              <div className='flex flex-col gap-4'>
                {JSON.parse(jsonrepair(text)).grants?.map(
                  (grant: any, index: number) => (
                    <Card
                      key={index}
                      className={cn(
                        'bg-surface dark:bg-dark-surface',
                        mode === GrantApplicationMode.DRAFTING
                          ? 'cursor-pointer'
                          : '',
                        index === selectedGrant
                          ? 'bg-primary/70 dark:bg-dark-primary/70'
                          : ''
                      )}
                      onClick={() => {
                        if (mode === GrantApplicationMode.DRAFTING) {
                          selectedGrant === index
                            ? setSelectedGrant(undefined)
                            : setSelectedGrant(index)
                        }
                      }}
                    >
                      <div className='flex flex-col gap-4'>
                        <div className='text-lg font-bold'>{grant.name}</div>
                        <ul className='list-disc'>
                          <li>
                            <span className='font-bold'>Criteria:</span>{' '}
                            {grant.criteria}
                          </li>
                          <li>
                            <span className='font-bold'>Alignment:</span>{' '}
                            {grant.alignment}
                          </li>
                          <li>
                            <span className='font-bold'>Amount:</span>{' '}
                            {grant.amount}
                          </li>
                          <li>
                            <span className='font-bold'>Deadline:</span>{' '}
                            {grant.deadline}
                          </li>
                          {grant.url && (
                            <li>
                              <span className='font-bold'>
                                More Information:
                              </span>{' '}
                              <Link
                                href={grant.url}
                                target='_blank'
                                rel='noreferrer'
                              >
                                {grant.url}
                              </Link>
                            </li>
                          )}
                        </ul>
                      </div>
                    </Card>
                  )
                )}
              </div>
            </div>

            {!loading && !progressException && (
              <>
                <div className='m-0 flex justify-between gap-2'>
                  <Button onClick={goBack} disabled={open}>
                    Back
                  </Button>
                  <div className='flex gap-2'>
                    <Button
                      disabled={open}
                      onClick={() => toggleModal('references')}
                      icon={<RiBook2Line className='size-5' />}
                    >
                      <span className='!hidden sm:!block'>References</span>
                    </Button>
                    <Popover
                      trigger='click'
                      className='cursor-pointer'
                      overlayClassName='w-full max-w-md'
                      onOpenChange={setOpen}
                      content={
                        <Form
                          autoComplete='off'
                          className='flex w-full flex-col items-end'
                          layout='vertical'
                        >
                          <Form.Item
                            name='additionalInfo'
                            rules={[{ type: 'string' }]}
                            className='w-full'
                          >
                            <Input.TextArea
                              rows={5}
                              placeholder='Tell us more about the types of grants you are interested in or any specific instructions to help us find the best matches for you!'
                              onChange={(e) =>
                                setAdditionalInfo(e.target.value)
                              }
                              className='w-full'
                            />
                          </Form.Item>
                          <Form.Item className='m-0'>
                            <Button
                              className='flex items-center'
                              htmlType='submit'
                              type='primary'
                              onClick={() => {
                                retry(additionalInfo)
                                setAdditionalInfo('')
                                setOpen(false)
                                setText(undefined)
                                setFinishedSteps(0)
                              }}
                              icon={<RiSparklingFill className='size-5' />}
                            >
                              Search again
                            </Button>
                          </Form.Item>
                        </Form>
                      }
                    >
                      <Button
                        className='flex items-center'
                        disabled={open}
                        icon={<RiSearchLine className='size-5' />}
                      >
                        <span className='!hidden sm:!block'>Search again</span>
                      </Button>
                    </Popover>
                  </div>
                  <Form.Item className='m-0'>
                    <Button
                      htmlType='submit'
                      type='primary'
                      icon={<RiSparklingFill className='size-5' />}
                      disabled={open && mode !== GrantApplicationMode.MATCHING}
                    >
                      Next
                    </Button>
                  </Form.Item>
                </div>
                <ReferencesModal
                  documents={
                    questions[questions.length - 1]?.messages[1]?.documents ??
                    []
                  }
                />
              </>
            )}
          </div>
          <div id='anchor' className='opacity-0'>
            a
          </div>
        </Form>
      ) : (
        <LoadingScreen
          finishedSteps={finishedSteps}
          allSteps={5}
          exception={progressException}
          lottieAnimation={lottieGrantsAnimation}
          text={
            progressException
              ? 'Error occurred while trying to fetch all available grants'
              : [
                  'Analyzing your company details for grant eligibility...',
                  'Identifying relevant funding categories for your business...',
                  'Scanning the web for available grant opportunities...',
                  'Gathering data on potential funding sources...',
                  'Evaluating grant criteria for a perfect match...',
                  'Reviewing eligibility requirements for each grant...',
                  'Shortlisting the most suitable grants for your company...',
                  'Analyzing funding amounts and deadlines...',
                  'Finalizing the best grant options tailored to your business...',
                  'Compiling your personalized list of top grant opportunities...',
                ]
          }
          goBack={goBack}
          retry={() => retry('')}
          timeInterval={20000}
        />
      )}
    </div>
  )
}

export default SelectGrantStep
